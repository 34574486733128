import React, { FC } from 'react';
import { Footer } from '@shared/types/umbraco/compositions';
import PromoFormComponent from '@promo-platform/promo-form';

import Button from 'gatsby-theme-husky/src/common/Button';
import { Container } from 'gatsby-theme-husky/src/layout';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import Icon from 'gatsby-theme-husky/src/common/IconCustom';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import SignUpLazy from 'gatsby-theme-husky/src/components/SignUp/components/SignUpLazy';

import { triggerCookiePopupOpen } from 'gatsby-theme-husky/src/utils/browser';

import '@promo-platform/styles';
import './FooterTopOverride.scss';

const FooterTop: FC<Footer.ITopFooter> = ({
  links,
  logo,
  navigationTitle,
  socialNetworks,
  socialNetworksTitle,
  signUp,
}) => (
  <div className="footer-top footer-top--dark-theme" data-testid="footer-top">
    <Container fluid>
      <div className="footer-top__grid">
        <div className="footer-top__logo">
          {logo?.[0].properties?.image ? (
            <GatsbyImage image={logo?.[0].properties?.image} className="footer-top__logo-img" />
          ) : null}
        </div>
        {signUp ? (
          signUp.promoFormId && signUp.promoFormUrl ? (
            <div className="footer-top__promo-form">
              <PromoFormComponent id={signUp.promoFormId} url={signUp.promoFormUrl} />
            </div>
          ) : (
            <SignUpLazy singupFormBaner={signUp} renderForm />
          )
        ) : null}
        {links?.length ? (
          <div className="footer-top__nav">
            <DangerouslySetInnerHtml className="footer-top__nav-title" html={navigationTitle} />
            <ul>
              {links.map(({ properties: { link, ariaLabel, openCookiePopup } }) => (
                <li key={link[0].name}>
                  <Button
                    variant="link"
                    target={link[0]?.target}
                    ariaLabel={ariaLabel}
                    {...(openCookiePopup
                      ? { clickHandler: triggerCookiePopupOpen() }
                      : { to: link[0].url })}
                  >
                    {link[0].name}
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <div className="footer-top__rest">
          {socialNetworks?.length ? (
            <div className="footer-top__social">
              <DangerouslySetInnerHtml
                className="footer-top__social-title"
                html={socialNetworksTitle}
              />
              <ul>
                {socialNetworks.map(({ properties: { icon, link, ariaLabel } }) => (
                  <li key={ariaLabel}>
                    <Button
                      variant="icon"
                      to={link?.[0].url}
                      target={link?.[0]?.target}
                      ariaLabel={ariaLabel}
                    >
                      <Icon icon={icon?.[0]} />
                      <span>{ariaLabel}</span>
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    </Container>
  </div>
);
export default FooterTop;

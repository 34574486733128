import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import { Navigation } from '@shared/types/umbraco/compositions';
import Button from 'gatsby-theme-husky/src/common/Button';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';
import NavigationDropdownItem from 'gatsby-theme-husky/src/components/Header/components/Navigation/components/NavigationDropdownItem';

const NavigationItem: FC<Navigation.INavigationItem> = ({
  link,
  label,
  classes,
  currentLink = '',
  withDropdown,
  subNavigation,
  imageBlockHeight,
}) => {
  const isOpen = useMemo(
    () =>
      link?.[0]?.properties?.link?.[0]?.url?.length > 1 &&
      currentLink.includes(link[0].properties.link[0].url),
    [currentLink, JSON.stringify(link)]
  );

  return (
    <>
      {withDropdown ? (
        <NavigationDropdownItem
          label={label}
          link={link}
          subNavigation={subNavigation}
          imageBlockHeight={imageBlockHeight}
        />
      ) : (
        <li
          data-testid="nav-item"
          key={label}
          className={classnames({
            open: isOpen,
            [classes]: classes,
          })}
        >
          <Button
            ariaLabel={link?.[0]?.properties?.ariaLabel}
            classes={classnames({ active: isOpen })}
            variant="link"
            to={link?.[0]?.properties?.link?.[0]?.url}
            activeClassName="active"
          >
            <DangerouslySetInnerHtml html={label} element="span" />
          </Button>
        </li>
      )}
    </>
  );
};

export default NavigationItem;

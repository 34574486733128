import React, { useEffect, useState, useRef } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { isBrowser } from 'utils/browser';
import SignUpComponent from './SignUpComponent';

import '../SignUp.scss';

const { GATSBY_GOOGLE_CAPTCHA_PUBLIC } = process.env;

const SignUpLazy = (props) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const observer =
    isBrowser() &&
    new IntersectionObserver(([entry]) => {
      if (!show && entry.isIntersecting) {
        setShow(true);
      }
    });

  useEffect(() => {
    observer?.observe(ref.current);

    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <div ref={ref}>
      {show ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={GATSBY_GOOGLE_CAPTCHA_PUBLIC}
          scriptProps={{ async: true, defer: false, appendTo: 'body' }}
        >
          <SignUpComponent {...props} />
        </GoogleReCaptchaProvider>
      ) : (
        <SignUpComponent {...props} />
      )}
    </div>
  );
};

export default SignUpLazy;

import React, { FC } from 'react';

import Button from 'gatsby-theme-husky/src/common/Button';
import { Container } from 'gatsby-theme-husky/src/layout';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import { Footer } from '@shared/types/umbraco/compositions';
import { triggerCookiePopupOpen } from 'gatsby-theme-husky/src/utils/browser';

import './FooterBottomOverride.scss';

const FooterBottom: FC<Footer.IBottomFooter> = ({ copyright, additionalLinks }) => {
  const copyString = copyright
    ? `© ${new Date().getFullYear()} ${copyright}`
    : `© ${new Date().getFullYear()}`;

  return (
    <div className="footer-bottom" data-testid="footer-bottom">
      <Container fluid>
        <div className="footer-bottom__grid">
          {additionalLinks?.length ? (
            <ul className="footer-bottom__links">
              {additionalLinks.map(({ properties: { link, ariaLabel, openCookiePopup } }) => (
                <li key={link[0].name}>
                  <Button
                    variant="link"
                    target={link[0]?.target}
                    ariaLabel={ariaLabel}
                    {...(openCookiePopup
                      ? { clickHandler: triggerCookiePopupOpen() }
                      : { to: link[0].url })}
                  >
                    {link[0].name}
                  </Button>
                </li>
              ))}
            </ul>
          ) : null}
          <DangerouslySetInnerHtml className="footer-bottom__copy" html={copyString} />
        </div>
      </Container>
    </div>
  );
};

export default FooterBottom;

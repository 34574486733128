import React, { FC, useRef, useState } from 'react';
import classnames from 'classnames';
import { Navigation } from '@shared/types/umbraco/compositions';
import useClickOutside from 'hooks/useClickOutside';
import Button from 'common/Button';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import ImageCard from 'common/ImageCard';

import './NavigationDropdownItem.scss';

const NavigationDropdownItem: FC<Navigation.INavigationDropdownItem> = ({
  link,
  label,
  subNavigation,
  imageBlockHeight,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef();

  const handleDropdownState = () => {
    setIsOpen((prevState) => !prevState);
  };

  useClickOutside(ref, () => setIsOpen(false));

  const submenuClasses = classnames('nav-item__submenu', {
    active: isOpen,
  });

  const dropdownItemClasses = classnames('nav-item__menu navigation__list-item', {
    active: isOpen,
    [`nav-item__menu--${imageBlockHeight}`]: imageBlockHeight,
  });

  return (
    <li data-testid="nav-dropdown-item" key={label} className={dropdownItemClasses} ref={ref}>
      <Button
        ariaLabel={link?.[0]?.properties?.ariaLabel}
        classes={classnames('btn-link__dropdown', { active: isOpen })}
        variant="link"
        onClick={handleDropdownState}
      >
        <DangerouslySetInnerHtml html={label} element="span" />
      </Button>

      {subNavigation?.length ? (
        <ul className={submenuClasses}>
          {subNavigation.map((item) => (
            <li className="nav-item__submenu-item" key={item?.properties.label}>
              {item?.properties.image ? (
                <ImageCard
                  tabIndex={isOpen ? 1 : -1}
                  isIconLink
                  description={item?.properties.description}
                  image={item?.properties.image}
                  link={item?.properties.link}
                />
              ) : null}
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default NavigationDropdownItem;

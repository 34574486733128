import React, { FC, useContext } from 'react';
import Button from 'gatsby-theme-husky/src/common/Button';
import { Header, SearchFormProps } from '@shared/types/umbraco';
import { CartContext } from 'gatsby-theme-husky/src/context/CartContextProvider/CartContextProvider';
import IconCustom from 'gatsby-theme-husky/src/common/IconCustom';
import SearchInput from 'gatsby-theme-husky/src/common/SearchInput/SearchInput';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';

const TopHeaderBlock: FC<{
  block: Header.ITopHeaderBlockEl[];
  search: SearchFormProps.ISearchForm;
}> = ({ block, search }) => {
  const { cartItemsQuantity } = useContext(CartContext);

  const { isDesktop } = useScreenRecognition();

  const getSearch = () => (
    <div className="search-container">
      <SearchInput
        searchPlaceholder={search.searchPlaceholder}
        ariaSearchSubmit={search.ariaSearchSubmit}
        ariaClear={search.ariaClear}
        ariaSearchInput={search.ariaSearchInput}
        handleCloseMenu={search.handleCloseMenu}
        ariaSearchForm={search.ariaSearchForm}
        searchUrl={search.searchUrl}
        gtmSearchSubmitEvent={search.gtmSearchSubmitEvent}
      />
    </div>
  );

  return (
    <div data-testid="navigation__block" className="navigation__block-wrapper">
      {search && getSearch()}
      {block.map((element) =>
        element.properties?.icon?.length ? (
          <div className="navigation__button-wrapper" key={element.properties.link[0].name}>
            <Button
              to={element.properties.link[0].url}
              variant="icon"
              classes="navigation__button"
              ariaLabel={element.properties.ariaLabel}
              target={element.properties.link[0].target}
            >
              {isDesktop &&
              element.properties.icon[0] !== 'cart' &&
              element.properties.icon[0] !== 'shopping-bag' ? (
                <span className="navigation__button__regular-link">
                  {element.properties.link[0]?.name}
                </span>
              ) : (
                <IconCustom icon={element.properties.icon[0]} />
              )}
            </Button>
            {element.properties.icon[0] === 'cart' && cartItemsQuantity > 0 && (
              <div className="navigation__button__cart-quantity">{cartItemsQuantity}</div>
            )}
          </div>
        ) : (
          <Button
            key={element.properties.link[0].url}
            variant="icon"
            to={element.properties.link[0].url}
            classes="navigation__button"
            ariaLabel={element.properties.ariaLabel}
            target={element.properties.link[0].target}
          >
            {element.properties.link[0].name}
          </Button>
        )
      )}
    </div>
  );
};
export default TopHeaderBlock;

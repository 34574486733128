import React, { FC } from 'react';
import classNames from 'classnames';
import { Header } from '@shared/types/umbraco/compositions';
import { Container } from 'gatsby-theme-husky/src/layout';
import LanguageSelector from 'gatsby-theme-husky/src/common/LanguageSelector/LangaugeSelector';
import TopHeaderBlock from './components/TopHeaderBlock';

import './TopHeaderOverride.scss';

const TopHeader: FC<Header.ITopHeader> = ({
  isMenuOpen,
  topHeaderLeftBlock,
  languageSelector,
  topHeaderRightBlock,
  currentLang,
  search,
}) => (
  <div
    data-testid="top-header"
    className={classNames('header__top ', { open: isMenuOpen }, 'header__top--dark-theme')}
  >
    <Container fluid>
      <div className="header__top-flex">
        {topHeaderLeftBlock?.length && search ? (
          <TopHeaderBlock block={topHeaderLeftBlock} search={search} />
        ) : null}
        {languageSelector?.length ? (
          <LanguageSelector
            inHeader={languageSelector[0].properties.inHeader}
            langs={languageSelector[0].properties.langs}
            redirectToHome={languageSelector[0].properties.redirectToHome}
            currentLang={currentLang}
          />
        ) : null}
        {search && <TopHeaderBlock block={topHeaderRightBlock} search={search} />}
      </div>
    </Container>
  </div>
);

export default TopHeader;
